import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Pages from "../../../components/Pages";
import { ContentBlock, TextBlock } from "../../../components/Blocks";
import CrowdfundingBlock from "../../../components/Crowdfunding/CrowdfundingBlock";
import withCrowdfunding from "../../../containers/withCrowdfunding";
import { removePreloader, translit, markdownConverter, removeEmptyKeys } from "../../../helpers";
import * as TargetBanner from "../../../components/TargetBanner";
import { useThemeContext } from "../../../components/Layout/ThemeContext";
import { Share } from "../../../widgets";
import Breadcrumbs from "../../../components/Layout/Breadcrumbs";

import "./Crowdfunding.scss";

export const query = graphql`
	query CrowdfundingQuery($slug: String!) {
		hasura {
			crowdfundingProjects {
				id
				slug
				path
				title
				description
				mainImage
				images
				currentAmount
				required_amount: requiredAmount
				progress
				sponsorsCount
				place
				startedAt
				endedAt
				tags
				goals
				kind
				donation {
					default_amount: defaultAmount
					steps
				}
			}
			text_pages(where: { slug: { _eq: $slug } }) {
				slug
				page_title_full
				main_image
				content_blocks
				seo
				template
			}
			...Banners
		}
	}
`;

function CrowdfundingProjects({ projectList, data }) {
	const { theme } = useThemeContext();

	const [filteredProjectList, setFilteredProjectList] = useState(get(data, "hasura.crowdfundingProjects", []).filter(item => item.kind === "donate"));
	const banners = get(data, "hasura.banners", []);
	const textPages = get(data, "hasura.text_pages[0]", {});
	const url = "how_to_help";

	useEffect(() => {
		if (projectList) {
			setFilteredProjectList(get(projectList, "crowdfundingProjects", []).filter(item => item.kind === "donate"));
		}
	}, [projectList]);

	const bannerBlock = banners.filter(banner => get(banner, "params.enabled", false) && get(banner, "params.locations", "").match(/how_to_help/)).map(banner => {
		return React.createElement(TargetBanner[get(banner, "params.type", "")] || TargetBanner.BannerImg, {
			...banner,
		});
	});
	const pageTitle = get(textPages, "page_title_full", "");
	const content_blocks = get(textPages, "content_blocks", "");

	useEffect(() => {
		removePreloader();
	}, []);

	return (
		<Pages entity={textPages} url={url} >
			{!!bannerBlock.length && <ContentBlock key={"banner"}>
				<div className={"banner__main-block d-noscript-none"}>{bannerBlock}</div>
			</ContentBlock>}
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={pageTitle} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className="container">
					<div className={"h1 text-page_title"}>
						<h1 itemProp="headline"
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h1,
								color: theme.color.text,
							})}>
							{pageTitle}
						</h1>
					</div>
					{content_blocks &&
					content_blocks.map(({ title, body }, idx) => {
						return (
							<TextBlock
								key={`${translit(title)}-${idx}`}
								title={title}
								desc={markdownConverter(body)}
							/>
						);
					})}
					<div className={"crowdfunding_projects"}>
						<CrowdfundingBlock data={filteredProjectList} />
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Хочу помочь!"} />
			</div>
		</Pages>
	);
}

export default withCrowdfunding(CrowdfundingProjects);

CrowdfundingProjects.propTypes = {
	projectList: PropTypes.object,
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

CrowdfundingProjects.defaultProps = {
	projectList: {},
};
