import React from "react";
import { graphql } from "@apollo/react-hoc";
import { flowRight as compose } from "lodash";

import {
	CURRENT_ORDER,
	UPDATE_CURRENT_ORDER,
	CROWDFUNDING_PROJECTS,
	PROJECTS,
} from "../../queries/queries.graphql";

export default function withCrowdfunding(Component) {
	class Crowdfunding extends React.Component {
		render() {
			return <Component {...this.props} />;
		}
	}

	Crowdfunding.displayName = `Crowdfunding(${Component.displayName || Component.name || "Component"})`;

	return compose(
		graphql(CROWDFUNDING_PROJECTS, {
			options: () => ({
				fetchPolicy: "no-cache",
			}),
			name: "projectList",
		}),
	)(Crowdfunding);
}

export function withCurrentOrder(Component) {
	class CurrentOrder extends React.Component {
		render() {
			return <Component {...this.props} />;
		}
	}

	CurrentOrder.displayName = `Crowdfunding(${Component.displayName || Component.name || "Component"})`;

	return compose(
		graphql(CURRENT_ORDER, {
			options: () => ({
				fetchPolicy: "no-cache",
			}),
			name: "currentOrder",
		}),
		graphql(UPDATE_CURRENT_ORDER, {
			options: () => ({
				fetchPolicy: "no-cache",
			}),
			name: "updateCurrentOrder",
		}),
	)(CurrentOrder);
}

export function withOrderList(Component) {
	class OrderList extends React.Component {
		render() {
			return <Component {...this.props} />;
		}
	}

	OrderList.displayName = `Crowdfunding(${Component.displayName || Component.name || "Component"})`;

	return compose(
		graphql(UPDATE_CURRENT_ORDER, {
			options: () => ({
				fetchPolicy: "no-cache",
			}),
			name: "updateCurrentOrder",
		}),
	)(OrderList);
}

export function withTrebs(Component) {
	class Trebs extends React.Component {
		render() {
			return <Component {...this.props} />;
		}
	}

	Trebs.displayName = `Crowdfunding(${Component.displayName || Component.name || "Component"})`;

	return compose(
		graphql(PROJECTS, {
			options: () => ({
				fetchPolicy: "no-cache",
			}),
			name: "projects",
		}),
		graphql(UPDATE_CURRENT_ORDER, {
			options: () => ({
				fetchPolicy: "no-cache",
			}),
			name: "updateCurrentOrder",
		}),
	)(Trebs);
}
